import { memo } from 'react';
import { useSelector } from 'react-redux';

import { useGetPrivateKanbanCustomField } from '~/components/KanbanView/KanbanColumnsManagers/DndKanbanManager/useUpdateKanbanItemPosition/useGetPrivateKanbanCustomField';
import { KanbanView } from '~/components/KanbanView/KanbanView';
import { KanbanProvider } from '~/components/KanbanView/Providers/KanbanProvider';
import { PrivateLibraryKanbanNullState } from '~/components/PrivateGallery/components/PrivateKanbanView/components/PrivateLibraryKanbanNullState';
import { ONE_HOUR } from '~/constants/unitsOfTime';
import { useCurrentBoardPermissions } from '~/hooks/useCurrentBoardPermissions';
import { useGetAvailableCustomFields } from '~/hooks/useGetAvailableCustomFields';
import { useTableHeaderPosition } from '~/hooks/useTableHeaderPosition';
import { useURLLibraryIdSelector } from '~/hooks/useURLLibraryIdSelector';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { centralizedBoardLibraryIdSelector } from '~/store/centralizedBoard/selectors';
import { currentKanbanGroupByCustomFieldIdSelector } from '~/store/configViews/selectors';
import { useGetPrivateCustomFieldOptions } from '~/swr-hooks/customFields/useGetPrivateCustomFieldOptions';
import { useGetPrivateCustomFields } from '~/swr-hooks/customFields/useGetPrivateCustomFields';
import { useUpdateItemsCustomFieldValue } from '~/utils/CustomFields/useUpdateItemsCustomFieldValue';
import { canSeeCustomFields } from '~/utils/permissions/workspacePermissions';

import { PrivateKanbanNullState } from './components/PrivateKanbanNullState';

export const PrivateKanbanView = memo(() => {
  const { tableHeaderTopPos } = useTableHeaderPosition();
  const { boardPermissions } = useCurrentBoardPermissions();
  const enabled = canSeeCustomFields(boardPermissions);
  const { data: customFields } = useGetPrivateCustomFields(enabled);
  const baseCustomField = useGetPrivateKanbanCustomField();
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;
  const customFieldId = useSelector(currentKanbanGroupByCustomFieldIdSelector);
  const { data: options } = useGetPrivateCustomFieldOptions({
    customFieldId,
    staleTime: ONE_HOUR,
  });

  const urlLibraryId = useURLLibraryIdSelector();
  const libraryId = useSelector(centralizedBoardLibraryIdSelector);
  const { getAvailableCustomFields, hasHiddenCustomFields } = useGetAvailableCustomFields({
    libraryId: libraryId || urlLibraryId,
  });

  const { updateItemsCustomFieldValue } = useUpdateItemsCustomFieldValue();

  return (
    <KanbanProvider
      customFieldValues={options?.data}
      onUpdateItems={async (params) => {
        await updateItemsCustomFieldValue({
          ...params,
          trackingLocation: 'kanban-view',
          workspaceId,
        });
      }}
      workspaceId={workspaceId}
    >
      <KanbanView
        NullState={hasHiddenCustomFields ? PrivateLibraryKanbanNullState : PrivateKanbanNullState}
        tableHeaderTopPos={tableHeaderTopPos}
        customFields={getAvailableCustomFields(customFields?.data)}
        baseCustomField={baseCustomField}
        customFieldValues={options?.data}
        canViewCustomFields={true}
      />
    </KanbanProvider>
  );
});

PrivateKanbanView.displayName = 'PrivateKanbanView';
