import { Button } from '@air/primitive-button';
import { useAirModal } from '@air/provider-modal';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import { KanbanNullState } from '~/components/KanbanNullState/KanbanNullState';
import { useLibraryPermissions } from '~/components/LibraryBeta/hooks/useLibraryPermissions';
import { LibrarySettingsModal } from '~/components/LibraryBeta/LibrarySettingsModal/LibrarySettingsModal';
import { useURLLibraryIdSelector } from '~/hooks/useURLLibraryIdSelector';
import { centralizedBoardLibraryIdSelector } from '~/store/centralizedBoard/selectors';
import { canChangeLibraryDetails } from '~/utils/permissions/libraryPermissions';

export const PrivateLibraryKanbanNullState = memo(() => {
  const urlLibraryId = useURLLibraryIdSelector();
  const boradLibraryId = useSelector(centralizedBoardLibraryIdSelector);
  const libraryId = boradLibraryId || urlLibraryId;
  const [showLibrarySettingsModal] = useAirModal(LibrarySettingsModal);
  const { libraryPermissions } = useLibraryPermissions({ libraryId });
  const canEditLibrary = canChangeLibraryDetails(libraryPermissions);

  return (
    <KanbanNullState
      title="This library doesn’t have any visible custom fields."
      action={
        canEditLibrary &&
        !!libraryId && (
          <Button
            appearance="filled"
            color="grey"
            onClick={() =>
              showLibrarySettingsModal({
                defaultTab: 'advanced-settings',
                libraryId,
              })
            }
            size="large"
          >
            Manage library settings
          </Button>
        )
      }
      text={
        canEditLibrary
          ? 'Update library settings to get started with Kanban view.'
          : 'Contact a Library Owner or Admin to update the library settings.'
      }
    />
  );
});

PrivateLibraryKanbanNullState.displayName = 'PrivateLibraryKanbanNullState';
