'use client';

import { Button } from '@air/primitive-button';
import { useBreakpointsContext } from '@air/provider-media-query';
import { memo } from 'react';

import { PrivateBoardAddButton } from '~/components/PrivateBoard/PrivateBoardAddButton';
import { PrivateBoardShareButton } from '~/components/PrivateBoard/PrivateBoardShareButton';
import { FollowButton } from '~/components/Shared/HeaderBar/FollowButton';
import { intercomIDs } from '~/constants/intercomIDs';
import { SHARE_BUTTON } from '~/constants/testIDs';

export const PrivateBoardTopBarActions = memo(() => {
  const { isAboveMediumScreen } = useBreakpointsContext();

  if (!isAboveMediumScreen) return null;

  return (
    <div className="flex shrink-0 items-center gap-3">
      <FollowButton />
      <PrivateBoardShareButton
        trigger={
          <Button
            appearance="filled"
            color="grey"
            data-testid={SHARE_BUTTON}
            data-intercomid={intercomIDs.HEADER_ACTIONS_SHARE_BUTTON}
            size="large"
          >
            Share
          </Button>
        }
      />
      <PrivateBoardAddButton />
    </div>
  );
});

PrivateBoardTopBarActions.displayName = 'PrivateBoardTopBarActions';
