import { useTrackClickedBoardGuestLearnMore } from '@air/analytics';
import { memo } from 'react';

import { NEW_TAB } from '~/constants/urls';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

const HELP_CENTER_ARTICLE = 'https://help.air.inc/en/articles/5107350-members-and-guests';

export const BoardGuestModalDescription = memo(() => {
  const { currentWorkspace } = useCurrentWorkspace();
  const { trackClickedBoardGuestLearnMore } = useTrackClickedBoardGuestLearnMore();
  if (!currentWorkspace) return null;

  return (
    <div className="flex">
      <span className="mr-0.5">
        Guests will be invited to join Air and will only have access to boards they are invited to.
        <a
          className="ml-1 font-semibold"
          onClick={trackClickedBoardGuestLearnMore}
          target={NEW_TAB}
          href={HELP_CENTER_ARTICLE}
        >
          Learn more →
        </a>
      </span>
    </div>
  );
});

BoardGuestModalDescription.displayName = 'BoardGuestModalDescription';
