import { Skeleton } from '@air/component-skeleton';

import { BoardHeader } from '~/_components/BoardHeader';

export const BoardHeaderSkeleton = () => {
  return (
    <BoardHeader
      ancestors={
        <div className="flex gap-2">
          <Skeleton height={24} width={72} isText />
          <Skeleton height={24} width={88} isText />
          <Skeleton height={24} width={64} isText />
        </div>
      }
      title={<Skeleton height={40} isText width={200} />}
      description={
        <div className="w-full max-w-[650px]">
          <Skeleton height={20} isText width="60%" />
          <Skeleton height={20} isText width="85%" />
          <Skeleton height={20} isText width="50%" />
        </div>
      }
    />
  );
};
