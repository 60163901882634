import { memo, MutableRefObject } from 'react';

import { PrivateBoardGalleryView } from '~/components/PrivateBoard/PrivateBoardGalleryView';
import { PrivateBoardTableView } from '~/components/PrivateBoard/PrivateBoardTableView';
import { PrivateKanbanView } from '~/components/PrivateGallery/components/PrivateKanbanView/PrivateKanbanView';
import { PrivateView } from '~/components/PrivateGallery/PrivateView';

import { usePrivateBoardGalleryContextMenu } from './usePrivateBoardGalleryContextMenu';

export interface PrivateBoardViewProps {
  scrollElementRef: MutableRefObject<HTMLDivElement | null>;
}

export const PrivateBoardView = memo(({ scrollElementRef }: PrivateBoardViewProps) => {
  const { ContextMenu, openContextMenu } = usePrivateBoardGalleryContextMenu();

  return (
    <PrivateView
      galleryView={<PrivateBoardGalleryView scrollElementRef={scrollElementRef} />}
      tableView={<PrivateBoardTableView scrollElementRef={scrollElementRef} />}
      kanbanView={<PrivateKanbanView />}
      ContextMenu={ContextMenu}
      onContextMenu={openContextMenu}
    />
  );
});

PrivateBoardView.displayName = 'PrivateBoardView';
