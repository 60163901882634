import { useTrackInvitedMember } from '@air/analytics';
import { WorkspaceUserRole } from '@air/api/types';
import { FormikField } from '@air/formik-field';
import { Badge } from '@air/primitive-badge';
import { Button } from '@air/primitive-button';
import { Input } from '@air/primitive-input';
import { useToasts } from '@air/provider-toast';
import { Form, Formik } from 'formik';
import { useResizeDetector } from 'react-resize-detector/build/withPolyfill';
import * as Yup from 'yup';

import { DropdownMenuWithSelectedLabelButton } from '~/components/Menus/DropdownMenuWithSelectedLabelButton';
import { INVITE_GUEST_MODAL_INPUT, PAID_SEAT } from '~/constants/testIDs';
import { useBoardPermissions } from '~/hooks/useBoardPermissions';
import { useSubscriptionContext } from '~/providers/SubscriptionProvider';
import { useCurrentWorkspaceMember } from '~/swr-hooks/members/useCurrentWorkspaceMember';
import { useInviteGuestToBoard } from '~/swr-hooks/members/useInviteGuestToBoard';
import { useWorkspaceGuestRoles } from '~/swr-hooks/members/useWorkspaceGuestRoles';
import { useWorkspaceMembers } from '~/swr-hooks/members/useWorkspaceMembers';
import { canInviteBoardGuests } from '~/utils/permissions/boardPermissions';
import { isPermissionsGTE } from '~/utils/PermissionsUtils';

interface BoardGuestInputProps {
  boardId: string;
  canUseGuestMembers: boolean;
}

const InviteSchema = Yup.object({
  email: Yup.string().email('Please enter a valid email address').default('').required('Email address is required'),
  selectedRole: Yup.object<WorkspaceUserRole>().required(),
});

/**
 * This is a form to invite a guest to a board.
 * It displays input with permissions and handles submitting guest's data
 */
export const BoardGuestInput = ({ boardId, canUseGuestMembers }: BoardGuestInputProps) => {
  const { showToast } = useToasts();
  const { guestRoles } = useWorkspaceGuestRoles();
  const { members } = useWorkspaceMembers();
  const { inviteGuestToBoard } = useInviteGuestToBoard();
  const { width = 0, ref } = useResizeDetector();
  const { currentWorkspaceMember } = useCurrentWorkspaceMember();
  const { trackInvitedMember } = useTrackInvitedMember();
  const { boardPermissions } = useBoardPermissions({ boardId });
  const { data: subscription } = useSubscriptionContext();

  if (!guestRoles || !boardPermissions) {
    return null;
  }

  const canInviteGuests = canInviteBoardGuests(boardPermissions);
  const isPerMemberModel = subscription?.isPerMemberModel;

  return (
    <Formik
      initialValues={{
        email: '',
        // start with the most restricted and free Guest Role
        selectedRole: guestRoles[guestRoles.length - 1],
      }}
      validationSchema={InviteSchema}
      enableReinitialize
      onSubmit={async ({ email, selectedRole }, { resetForm }) => {
        try {
          await inviteGuestToBoard(boardId, email, selectedRole, members?.allMembers || []);
          trackInvitedMember({
            location: 'board',
            role: selectedRole.displayName,
            type: selectedRole.type,
            invitorRole: currentWorkspaceMember?.role?.displayName,
          });
        } catch (_error) {
          showToast(`There was an issue with inviting a guest. Please try again later.`);
        } finally {
          resetForm();
        }
      }}
    >
      {({ values, errors, setFieldValue, isSubmitting }) => {
        return (
          <Form>
            <div className="flex flex-1" data-testid={INVITE_GUEST_MODAL_INPUT}>
              <div className="relative flex flex-1">
                <FormikField
                  className="flex-1"
                  isLabelHidden
                  id="email"
                  label="Work email"
                  component={
                    <Input
                      autoComplete="username"
                      className="flex-1"
                      data-1p-ignore
                      data-lpignore
                      disabled={!canUseGuestMembers || !canInviteGuests}
                      placeholder="Email"
                      required={true}
                      size="extra-large"
                      style={{
                        paddingRight: width + 16,
                      }}
                      type="email"
                    />
                  }
                  name="email"
                />
                <div className="absolute inset-y-0 right-2 flex items-center" ref={ref}>
                  <DropdownMenuWithSelectedLabelButton
                    selectedOption={{
                      id: values.selectedRole.id,
                      label: values.selectedRole.displayName,
                    }}
                    options={guestRoles.map((role) => {
                      const { displayName, description, id, billable, permissions } = role;

                      return {
                        id,
                        onSelect: () => {
                          if (values.selectedRole.id === id) return;
                          setFieldValue('selectedRole', role);
                        },
                        label: (
                          <div className="flex h-6 items-center">
                            {displayName}
                            {canUseGuestMembers && billable && isPerMemberModel && (
                              <Badge className="ml-2" color="teal" data-testid={PAID_SEAT}>
                                Paid Seat
                              </Badge>
                            )}
                          </div>
                        ),
                        description: description || undefined,
                        disabled: !canUseGuestMembers || !isPermissionsGTE(boardPermissions, permissions),
                        type: 'item',
                      };
                    })}
                  />
                </div>
              </div>
              <Button
                appearance="filled"
                color="blue"
                className="ml-3"
                disabled={!canUseGuestMembers || !values.email || !!errors.email || !canInviteGuests}
                isLoading={isSubmitting}
                size="extra-large"
                type="submit"
              >
                Invite
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
