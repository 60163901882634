import { Star, StarFilled } from '@air/next-icons';
import { IconButton, IconButtonProps } from '@air/primitive-icon-button';
import { Tooltip } from '@air/primitive-tooltip';
import { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { centralizedBoardHasCurrentUserSelector, centralizedBoardSelector } from '~/store/centralizedBoard/selectors';
import { isPrivateBoard } from '~/store/centralizedBoard/utils';
import { useToggleFavoritedBoard } from '~/swr-hooks/boards/useToggleFavoritedBoard';
import { useAirStore } from '~/utils/ReduxUtils';

export type PrivateBoardFavoriteButtonProps = Pick<IconButtonProps, 'appearance' | 'color' | 'size'>;

export const PrivateBoardFavoriteButton = memo(
  ({ appearance = 'ghost', color = 'grey', size = 'medium' }: PrivateBoardFavoriteButtonProps) => {
    const store = useAirStore();
    const hasCurrentUser = useSelector(centralizedBoardHasCurrentUserSelector);
    const { toggleFavoritedBoard } = useToggleFavoritedBoard();

    const onClick = useCallback(() => {
      const board = centralizedBoardSelector(store.getState());

      if (board && isPrivateBoard(board)) toggleFavoritedBoard({ board, trackLocation: 'header' });
    }, [store, toggleFavoritedBoard]);

    const isFavorited = !!hasCurrentUser;
    const label = isFavorited ? 'Remove from Favorites' : 'Add to Favorites';

    return (
      <Tooltip label={label}>
        <IconButton
          appearance={appearance}
          color={color}
          data-testid="PRIVATE_BOARD_FAVORITE_BUTTON"
          icon={isFavorited ? StarFilled : Star}
          label={label}
          onClick={onClick}
          size={size}
        />
      </Tooltip>
    );
  },
);

PrivateBoardFavoriteButton.displayName = 'PrivateBoardFavoriteButton';
