import { WorkspaceMemberType } from '@air/api/types';
import { useMemo } from 'react';

import { useWorkspaceRolesContext } from '~/providers/WorkspaceRolesProvider';

export const useWorkspaceGuestRoles = () => {
  const { data: roles } = useWorkspaceRolesContext();
  const guestRoles = useMemo(() => roles?.filter(({ type }) => type === WorkspaceMemberType.guest), [roles]);

  return { guestRoles };
};
