import { memo } from 'react';
import { useSelector } from 'react-redux';

import { PrivateSearchResultsNumber } from '~/components/Search/PrivateSearchResultsNumber';
import { centralizedBoardTitleSelector } from '~/store/centralizedBoard/selectors';
import { useCanShowBoards } from '~/swr-hooks/gallery/useCanShow';

export const PrivateBoardSearchResultsNumber = memo(() => {
  const boardName = useSelector(centralizedBoardTitleSelector);
  const { canShowBoards } = useCanShowBoards();

  return <PrivateSearchResultsNumber canShowBoards={canShowBoards} location={boardName} trackLocation="board" />;
});

PrivateBoardSearchResultsNumber.displayName = 'PrivateBoardSearchResultsNumber';
