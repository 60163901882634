import { Ellipsis } from '@air/next-icons';
import { DropdownMenu, renderDropdownItems } from '@air/primitive-dropdown-menu';
import { IconButton, IconButtonProps } from '@air/primitive-icon-button';
import { memo, useState } from 'react';

import { usePrivateBoardHeaderMenuOptions } from '~/components/PrivateBoard/usePrivateBoardHeaderMenuOptions';
import { BOARD_ACTIONS_SUBMENU_BUTTON } from '~/constants/testIDs';

export type PrivateBoardMoreOptionsButtonProps = Pick<IconButtonProps, 'appearance' | 'color' | 'size'>;

export const PrivateBoardMoreOptionsButton = memo(
  ({ appearance = 'ghost', color = 'grey', size = 'medium' }: PrivateBoardMoreOptionsButtonProps) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { headerMenuOptions } = usePrivateBoardHeaderMenuOptions();
    const label = 'More options';

    if (!headerMenuOptions.length) return null;

    return (
      <DropdownMenu
        open={isMenuOpen}
        onOpenChange={setIsMenuOpen}
        trigger={
          <IconButton
            appearance={appearance}
            color={color}
            data-testid={BOARD_ACTIONS_SUBMENU_BUTTON}
            icon={Ellipsis}
            label={label}
            size={size}
          />
        }
      >
        {isMenuOpen && renderDropdownItems({ options: headerMenuOptions })}
      </DropdownMenu>
    );
  },
);

PrivateBoardMoreOptionsButton.displayName = 'PrivateBoardMoreOptionsButton';
