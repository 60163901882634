import { Boards } from '@air/api';
import { InfiniteData, useMutation, useQueryClient } from '@tanstack/react-query';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { getFollowingBoardsKey } from '~/swr-hooks/followedBoards/useFollowingBoards';
import { isBoardFollowerKey } from '~/swr-hooks/followedBoards/useIsFollowingBoard';
import { BoardWithAncestorsResponse } from '~/types/boards';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

export const useUnfollowBoards = () => {
  const queryClient = useQueryClient();
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;

  const unfollowBoards = useMutation({
    mutationFn: (boardIds: string[]) => {
      if (!workspaceId) {
        throw new Error('No workspaceId found');
      }

      return Boards.unfollow({ boardIds, workspaceId });
    },
    onMutate: (boardIds) => {
      const previousFollowingBoards = queryClient.getQueryData<InfiniteData<BoardWithAncestorsResponse>>(
        getFollowingBoardsKey(workspaceId),
      );

      boardIds.forEach((boardId) => queryClient.setQueryData<boolean | undefined>(isBoardFollowerKey(boardId), false));

      queryClient.setQueryData<InfiniteData<BoardWithAncestorsResponse>>(
        getFollowingBoardsKey(workspaceId),
        (oldData) =>
          oldData
            ? {
                ...oldData,
                pages: oldData.pages.map((page) => ({
                  ...page,
                  total: Math.max(0, page.total - boardIds.length),
                  data: page.data.filter((board) => !boardIds.includes(board.id)),
                })),
              }
            : oldData,
      );

      return {
        previousFollowingBoards,
      };
    },
    onError: (error, boardIds, context) => {
      boardIds.forEach((boardId) => queryClient.setQueryData<boolean | undefined>(isBoardFollowerKey(boardId), true));

      queryClient.setQueryData<InfiniteData<BoardWithAncestorsResponse>>(
        getFollowingBoardsKey(workspaceId),
        context?.previousFollowingBoards,
      );
      reportErrorToBugsnag({ error, context: 'Failed to unfollow boards', metadata: { data: { boardIds } } });
    },
  });

  return {
    unfollowBoards,
  };
};
