import { Button } from '@air/primitive-button';
import { Modal, ModalCloseButton, ModalDescription, ModalTitle } from '@air/primitive-modal';

import { GUEST_UPGRADE_CONFIRMATION_MODAL } from '~/constants/testIDs';

export interface UpgradeGuestRoleProps {
  member: {
    firstName?: string;
    lastName?: string;
  };
  onConfirm: () => void;
}

export const UpgradeGuestRole = ({
  onClose,
  member: { firstName, lastName },
  onConfirm,
}: AirModalProps<UpgradeGuestRoleProps>) => (
  <Modal isOpen onDismiss={onClose} data-testid={GUEST_UPGRADE_CONFIRMATION_MODAL}>
    <div className="flex flex-col gap-6">
      <header className="flex justify-between gap-2">
        <ModalTitle>Are you sure?</ModalTitle>
        <ModalCloseButton onClick={onClose} />
      </header>
      <ModalDescription>
        Upgrading {firstName && lastName ? `${firstName} ${lastName}` : 'this guest'} from a viewer will give them more
        access to the board and your bill will be updated accordingly.
      </ModalDescription>
      <footer className="flex items-center justify-end gap-2">
        <Button appearance="ghost" color="grey" onClick={onClose} size="large">
          Cancel
        </Button>
        <Button appearance="filled" color="blue" size="large" onClick={onConfirm}>
          Update
        </Button>
      </footer>
    </div>
  </Modal>
);
