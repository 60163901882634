import { WorkspaceMemberResponse, WorkspaceUserRole } from '@air/api/types';
import { reportErrorToBugsnag } from '@air/utils-error';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { getBoardsGuestsKey } from '~/swr-hooks/boards/useFetchBoardGuests';
import { sanitizeEmail } from '~/utils/EmailUtils';

import { useAddWorkspaceMembers } from './useAddWorkspaceMembers';

export const useInviteGuestToBoard = () => {
  const { addWorkspaceMember } = useAddWorkspaceMembers();
  const { currentWorkspace } = useCurrentWorkspace();
  const queryClient = useQueryClient();

  const inviteGuestToBoard = useCallback(
    async (boardId: string, email: string, role: WorkspaceUserRole, members: WorkspaceMemberResponse[]) => {
      try {
        await addWorkspaceMember([
          {
            email: sanitizeEmail(email),
            boardId,
            roleId: role.id,
            type: role.type,
          },
        ]);
        await queryClient.invalidateQueries({
          queryKey: getBoardsGuestsKey({ boardId, workspaceId: currentWorkspace?.id }),
        });
      } catch (error) {
        throw reportErrorToBugsnag({
          error,
          context: 'Failed to add a board guest',
          metadata: {
            data: {
              boardId,
              email,
              role,
              members,
            },
          },
        });
      }
    },
    [addWorkspaceMember, currentWorkspace?.id, queryClient],
  );

  return {
    inviteGuestToBoard,
  };
};
