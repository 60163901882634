import { Boards } from '@air/api';
import { Board } from '@air/api/types';
import { useQuery } from '@tanstack/react-query';

import { getPrivateBoardStatsKey } from '~/constants/react-query-keys';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

export const useGetPrivateBoardStats = ({
  boardId,
  includeDescendants,
}: {
  boardId?: Board['id'];
  includeDescendants: boolean;
}) => {
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;

  return useQuery({
    queryKey: getPrivateBoardStatsKey({ workspaceId, boardId, includeDescendants }),

    queryFn: () => {
      if (!workspaceId) {
        throw new Error('No workspaceId found');
      }

      if (!boardId) {
        throw new Error('No workspaceId found');
      }

      return Boards.getBoardStats({
        boardId,
        workspaceId,
        includeDescendants,
      });
    },
    enabled: !!boardId && !!workspaceId,
  });
};
