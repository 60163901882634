import { memo } from 'react';

import { PrivateBoardGalleryBoard } from '~/components/PrivateGallery/PrivateBoardGalleryBoard';
import { PrivateGalleryAsset } from '~/components/PrivateGallery/PrivateGalleryAsset';
import { PrivateGalleryFile } from '~/components/PrivateGallery/PrivateGalleryFile';
import { PrivateGalleryView, PrivateGalleryViewProps } from '~/components/PrivateGallery/PrivateGalleryView';
import { useCurrentBoardPermissions } from '~/hooks/useCurrentBoardPermissions';
import { canChangeBoardAssetsOrBoardsPosition } from '~/utils/permissions/boardPermissions';

export interface PrivateBoardGalleryViewProps {
  scrollElementRef: PrivateGalleryViewProps['scrollElementRef'];
}

export const PrivateBoardGalleryView = memo(({ scrollElementRef }: PrivateBoardGalleryViewProps) => {
  const { boardPermissions } = useCurrentBoardPermissions();
  return (
    <PrivateGalleryView
      scrollElementRef={scrollElementRef}
      renderBoard={({ box, data, index }) => (
        <PrivateBoardGalleryBoard box={box} data={data} index={index} key={data.id} />
      )}
      renderAsset={({ box, data, index }) => <PrivateGalleryAsset box={box} data={data} index={index} key={data.id} />}
      renderFile={({ box, data, index }) => <PrivateGalleryFile box={box} data={data} index={index} key={data.id} />}
      showBoards
      canRearrange={canChangeBoardAssetsOrBoardsPosition(boardPermissions)}
    />
  );
});

PrivateBoardGalleryView.displayName = 'PrivateBoardGalleryView';
