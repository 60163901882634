import { useAirModal } from '@air/provider-modal';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { displaySlackChannelName } from '~/components/EditPrivateBoardModal/EditBoardModal/SlackIntegration/utils/displaySlackChannelName';
import { useEditBoard } from '~/components/EditPrivateBoardModal/hooks/useEditBoard';
import { CreateBoardModal } from '~/components/Modals/CreateBoardModal';
import { useDeleteBoardsModal } from '~/components/Modals/DeleteBoards/useDeleteBoardsModal';
import { useDuplicateBoard } from '~/components/Modals/DuplicateBoard/hooks/useDuplicateBoard';
import { useMoveItemsModal } from '~/components/Modals/MoveItemsModal/useMoveItemsModal';
import { useGetPrivateBoardMenuOptions } from '~/hooks/getPrivateBoardMenuOptions';
import { useCanShowInFinderOption } from '~/hooks/useCanShowInFinderOption';
import { useCurrentBoardPermissions } from '~/hooks/useCurrentBoardPermissions';
import { useDownloadPrivateBoard } from '~/hooks/useDownloadPrivateBoard';
import { useDesktopAppConnectionContext } from '~/providers/DesktopAppConnectionProvider';
import { centralizedBoardSelector } from '~/store/centralizedBoard/selectors';
import { isPrivateBoard } from '~/store/centralizedBoard/utils';
import { useBoardContainerPermissions } from '~/swr-hooks/boards/useBoardContainerPermissions';
import { useSlackMenuOptions } from '~/utils/menuOptions/useSlackMenuOptions';
import {
  canChangeBoardDetails,
  canCreateBoard,
  canDeleteBoard,
  canDuplicateBoard,
} from '~/utils/permissions/boardPermissions';
import { canGenerateZip } from '~/utils/permissions/taskPermissions';

export const usePrivateBoardHeaderMenuOptions = () => {
  const board = useSelector(centralizedBoardSelector);
  const [showCreateBoardModal] = useAirModal(CreateBoardModal);
  const { downloadPrivateBoard } = useDownloadPrivateBoard();
  const { showDuplicateBoardsModal } = useDuplicateBoard();
  const { showMoveItemsModal } = useMoveItemsModal();
  const { showDeleteBoardsModal } = useDeleteBoardsModal();
  const { showEditBoardModal } = useEditBoard();
  const { onConnectToSlack, onConnectedToSlack, slackIntegrationData } = useSlackMenuOptions(
    board && isPrivateBoard(board) ? board : undefined,
  );
  const { canShowOpenInFinder } = useCanShowInFinderOption();
  const { openBoardInFinder } = useDesktopAppConnectionContext();

  const { getBoardMenuOptions } = useGetPrivateBoardMenuOptions();

  const { boardPermissions } = useCurrentBoardPermissions();

  const { boardContainerPermissions } = useBoardContainerPermissions(board);

  const headerMenuOptions = useMemo(
    () =>
      board && isPrivateBoard(board)
        ? getBoardMenuOptions({
            onMove: canDeleteBoard(boardContainerPermissions)
              ? () => showMoveItemsModal({ boards: [board], trackLocation: 'header' })
              : undefined,
            onEdit: canChangeBoardDetails(boardPermissions)
              ? () => showEditBoardModal({ board: board, trackLocation: 'header' })
              : undefined,
            onAddSubBoard: canCreateBoard(boardContainerPermissions)
              ? () => showCreateBoardModal({ selectedParentBoard: board, trackLocation: 'header' })
              : undefined,
            onDownload: canGenerateZip(boardPermissions)
              ? () => downloadPrivateBoard({ boardId: board.id, trackLocation: 'header' })
              : undefined,
            onDuplicate: canDuplicateBoard({
              boardContext: boardPermissions,
              targetContext: boardContainerPermissions,
            })
              ? () => showDuplicateBoardsModal({ boards: [board], trackLocation: 'header' })
              : undefined,
            onDelete: canDeleteBoard(boardContainerPermissions)
              ? () => showDeleteBoardsModal({ boardIds: [board.id], trackLocation: 'header' })
              : undefined,
            onConnectToSlack: onConnectToSlack ? () => onConnectToSlack('header') : undefined,
            onConnectedToSlack:
              onConnectedToSlack && slackIntegrationData
                ? {
                    onSelect: () => onConnectedToSlack('header'),
                    description: displaySlackChannelName(slackIntegrationData.channelName),
                  }
                : undefined,
            onOpenOnDesktop:
              /**
               * @see https://air-labs-team.slack.com/archives/C052RDYCGAG/p1696874696775379
               */
              canShowOpenInFinder && canGenerateZip(boardPermissions) ? () => openBoardInFinder(board.id) : undefined,
          })
        : [],
    [
      board,
      boardContainerPermissions,
      canShowOpenInFinder,
      downloadPrivateBoard,
      getBoardMenuOptions,
      onConnectToSlack,
      onConnectedToSlack,
      openBoardInFinder,
      boardPermissions,
      showCreateBoardModal,
      showDeleteBoardsModal,
      showDuplicateBoardsModal,
      showEditBoardModal,
      showMoveItemsModal,
      slackIntegrationData,
    ],
  );

  return {
    headerMenuOptions,
  };
};
