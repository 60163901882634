import { ViewTypeName } from '@air/api/types';
import { useBreakpointsContext } from '@air/provider-media-query';
import { memo, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { MobileToggleNestedItemsButton } from '~/_components/MobileToggleNestedItemsButton';
import { PrivateBoardSearchResultsNumber } from '~/components/Search/PrivateBoardSearchResultsNumber';
import { ViewControls, ViewControlsProps } from '~/components/ViewControls/ViewControls';
import { ViewSortingMenu } from '~/components/ViewControls/ViewSortingMenu/ViewSortingMenu';
import { useIsSearchActive } from '~/hooks/search/useIsSearchActive';
import { usePrivateTableHeaderPadding } from '~/hooks/usePrivateTableHeaderPadding';
import { currentViewTypeNameSelector } from '~/store/configViews/selectors';

import { PrivateBoardSaveViewButton } from './PrivateBoardSaveViewButton';
import { PrivateBoardViewControlsBoardHeader } from './PrivateBoardViewControlsBoardHeader';
import { PrivateViewControlsButtons } from './PrivateViewControlsButtons';

export type PrivateBoardViewControlsProps = Pick<ViewControlsProps, 'scrollRef' | 'headerRef'>;

export const PrivateBoardViewControls = memo(({ headerRef, scrollRef }: PrivateBoardViewControlsProps) => {
  const currentViewTypeName = useSelector(currentViewTypeNameSelector);
  const inGalleryView = currentViewTypeName === ViewTypeName.gallery;
  const inKanbanView = currentViewTypeName === ViewTypeName.kanban;
  const [isScrolledToTop, setIsScrolledToTop] = useState(false);
  const { isSearchActive } = useIsSearchActive();
  const { headerHorizontalPadding } = usePrivateTableHeaderPadding();
  const { isAboveMediumScreen } = useBreakpointsContext();

  const header = useMemo(() => {
    if (isSearchActive && isAboveMediumScreen) {
      return <PrivateBoardSearchResultsNumber />;
    } else if (isScrolledToTop) {
      return <PrivateBoardViewControlsBoardHeader />;
    } else if (!isAboveMediumScreen && (inGalleryView || inKanbanView)) {
      return <ViewSortingMenu />;
    }
  }, [inGalleryView, inKanbanView, isAboveMediumScreen, isScrolledToTop, isSearchActive]);

  const buttons = useMemo(() => {
    return (
      <div className="flex w-full items-center gap-2">
        <PrivateBoardSaveViewButton />
        {!isAboveMediumScreen && <MobileToggleNestedItemsButton />}
        <PrivateViewControlsButtons showViewSortingMenu={isAboveMediumScreen} />
      </div>
    );
  }, [isAboveMediumScreen]);

  const tx = useMemo(() => ({ px: headerHorizontalPadding }), [headerHorizontalPadding]);

  return (
    <ViewControls
      onScrolled={setIsScrolledToTop}
      scrollRef={scrollRef}
      headerRef={headerRef}
      header={header}
      buttons={buttons}
      tx={tx}
    />
  );
});

PrivateBoardViewControls.displayName = 'PrivateBoardViewControls';
