import { memo, useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { useGeneralLibraryTitle } from '~/components/LibraryBeta/hooks/useGeneralLibraryTitle';
import { BasePrivateSearchProps, PrivateSearch } from '~/components/Search/PrivateSearch';
import { usePrivateBoardSearchToken } from '~/components/Search/SearchTokens/usePrivateBoardSearchToken';
import { SearchBarHandle } from '~/components/SearchBar/SearchBar';
import { createLibraryRoute, Routes } from '~/constants/routes';
import { QueryParamNames } from '~/constants/search';
import { useStickyResult } from '~/hooks/useStickyResult';
import { useURLBoardIdSelector } from '~/hooks/useURLBoardIdSelector';
import { centralizedBoardLibrarySelector, centralizedBoardTitleSelector } from '~/store/centralizedBoard/selectors';
import { usePrivateSearchSuggestions } from '~/swr-hooks/search/usePrivateSearchSuggestions';
import { DefaultLibraryIcon, GeneralLibrary } from '~/utils/librariesUtils';
import { pushWithExistingQuery } from '~/utils/PathUtils';

type PrivateBoardSearchProps = BasePrivateSearchProps;

export const PrivateBoardPageSearch = memo(({ onCloseClick, trackLocation }: PrivateBoardSearchProps) => {
  const boardId = useURLBoardIdSelector();
  const boardName = useSelector(centralizedBoardTitleSelector);
  const boardLibrary = useSelector(centralizedBoardLibrarySelector);
  const searchBarRef = useRef<SearchBarHandle>(null);
  const { generalLibraryTitle } = useGeneralLibraryTitle();

  const [searchTerm, setSearchTerm] = useState('');

  const [searchBoardId, setSearchBoardId] = useState(boardId);

  const [isFocused, setIsFocused] = useState(false);

  const onSearchTokenToggle = useCallback(
    (isVisible: boolean) => {
      if (isVisible) {
        setSearchBoardId(boardId);
      } else {
        setSearchBoardId('');
      }
    },
    [boardId],
  );

  const libraryTokenInfo = useMemo(() => {
    if (!boardLibrary) {
      return {
        ...GeneralLibrary,
        title: generalLibraryTitle ?? '',
      };
    } else {
      return {
        id: boardLibrary.id,
        title: boardLibrary.title,
        Icon: DefaultLibraryIcon,
      };
    }
  }, [boardLibrary, generalLibraryTitle]);

  const { onBackspacePress, isBoardTokenRemoved, SearchToken, showTokens, isLibraryTokenRemoved } =
    usePrivateBoardSearchToken({
      library: libraryTokenInfo,
      isFocused,
      hasValue: !!searchTerm,
      boardName,
      onSearchTokenToggle,
      searchBarRef,
    });

  const { data, refetch } = usePrivateSearchSuggestions({
    search: searchTerm,
    boardId: searchBoardId,
    libraryId: isLibraryTokenRemoved ? undefined : boardLibrary?.id,
    canFetch: isFocused,
  });
  // this hook prevents passing empty data to SearchBar while suggestions are loading
  // It is used to keep previous suggestions visible while new ones are not returned yet
  const suggestions = useStickyResult(data);

  const onFocusChange = useCallback(
    (isFocused: boolean) => {
      setIsFocused(isFocused);
      if (isFocused) {
        refetch();
      }
    },
    [refetch],
  );

  const onApplyTextSearch = useCallback(
    async (newTerm: string) => {
      if (isBoardTokenRemoved) {
        if (isLibraryTokenRemoved) {
          await pushWithExistingQuery({
            path: Routes.media.all,
            newQuery: { [QueryParamNames.Search]: newTerm },
          });
        } else {
          await pushWithExistingQuery({
            path: createLibraryRoute(libraryTokenInfo.id),
            newQuery: { [QueryParamNames.Search]: newTerm },
          });
        }
      }
      setSearchTerm(newTerm);
    },
    [isBoardTokenRemoved, isLibraryTokenRemoved, libraryTokenInfo.id],
  );

  const placeholder = useMemo(() => {
    if (isBoardTokenRemoved) {
      if (isLibraryTokenRemoved) {
        return 'Search all assets & files';
      } else {
        return 'Search library';
      }
    }
    return `Search board`;
  }, [isBoardTokenRemoved, isLibraryTokenRemoved]);

  return (
    <PrivateSearch
      onApplyTextSearch={onApplyTextSearch}
      searchBarRef={searchBarRef}
      onCloseClick={onCloseClick}
      trackLocation={trackLocation}
      suggestions={suggestions}
      onSearchClear={showTokens}
      onSearchChange={setSearchTerm}
      onFocusChange={onFocusChange}
      onBackspacePress={onBackspacePress}
      placeholder={placeholder}
    >
      {SearchToken}
    </PrivateSearch>
  );
});

PrivateBoardPageSearch.displayName = 'PrivateBoardSearch';
