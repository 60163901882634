import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useLibraryCustomFields } from '~/components/LibraryBeta/hooks/queries/useLibraryCustomFields';
import { usePrivateCustomFieldsPanel } from '~/components/PrivateBoard/components/PrivateBoardCustomFieldsPanel/hooks/usePrivateCustomFieldsPanel';
import { BoardBlurbCustomFields } from '~/components/Workspace/BoardHeader/BoardCrumbs/BoardBlurbCustomFields';
import { centralizedBoardLibraryIdSelector } from '~/store/centralizedBoard/selectors';
import { usePrivateBoardCustomFieldsData } from '~/swr-hooks/customFields/usePrivateBoardCustomFieldsData';

export const PrivateBoardHeaderCustomFields = memo(() => {
  const { data: boardCustomFields, isLoading: isBoardCustomFieldsLoading } = usePrivateBoardCustomFieldsData();
  const { togglePanel } = usePrivateCustomFieldsPanel();
  const libraryId = useSelector(centralizedBoardLibraryIdSelector);
  const { data: libraryCustomFields, isLoading: isLibraryCustomFieldsLoading } = useLibraryCustomFields({
    libraryId,
  });

  const customFields = useMemo(() => {
    if (libraryId && boardCustomFields?.data && libraryCustomFields?.data) {
      return boardCustomFields?.data.filter((boardCustomField) => {
        return !!libraryCustomFields.data.find((libraryCustomField) => libraryCustomField.id === boardCustomField.id)
          ?.visible;
      });
    }

    return boardCustomFields?.data;
  }, [boardCustomFields, libraryCustomFields?.data, libraryId]);

  if (!customFields?.length || isLibraryCustomFieldsLoading || isBoardCustomFieldsLoading) return null;

  return <BoardBlurbCustomFields customFields={customFields} onPanelClick={togglePanel} />;
});

PrivateBoardHeaderCustomFields.displayName = 'PrivateBoardHeaderCustomFields';
