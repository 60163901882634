import { isEqual } from 'lodash';
import { memo } from 'react';

import { useIsSearchActive } from '~/hooks/search/useIsSearchActive';

import { PrivateGalleryBoard, PrivateGalleryBoardProps } from './PrivateGalleryBoard';
import { PrivateGalleryBoardAncestors } from './PrivateGalleryBoardAncestors';

type PrivateBoardGalleryBoardProps = Omit<PrivateGalleryBoardProps, 'trackLocation' | 'ancestors'>;

export const PrivateBoardGalleryBoard = memo(({ index, box, data }: PrivateBoardGalleryBoardProps) => {
  const { isSearchActive } = useIsSearchActive();

  return (
    <PrivateGalleryBoard
      index={index}
      box={box}
      data={data}
      ancestors={
        isSearchActive ? (
          <PrivateGalleryBoardAncestors libraryTitle={data.library?.title} ancestors={data.ancestors} />
        ) : undefined
      }
    />
  );
}, isEqual);

PrivateBoardGalleryBoard.displayName = 'PrivateBoardGalleryBoard';
