import { memo } from 'react';

import { PrivateTableView, PrivateTableViewProps } from '~/components/TableView/PrivateTableView';
import { PrivateAssetTableRow } from '~/components/TableView/PrivateTableView/PrivateAssetTableRow';
import { PrivateBoardTableRow } from '~/components/TableView/PrivateTableView/PrivateBoardTableRow';

import { PrivateBoardNullState } from './PrivateBoardNullState';

export interface PrivateBoardTableViewProps {
  scrollElementRef: PrivateTableViewProps['scrollElementRef'];
}

export const PrivateBoardTableView = memo(({ scrollElementRef }: PrivateBoardTableViewProps) => {
  return (
    <PrivateTableView
      scrollElementRef={scrollElementRef}
      EmptyState={PrivateBoardNullState}
      renderBoardRow={({ data, key, rowStyle }) => <PrivateBoardTableRow key={key} data={data} style={rowStyle} />}
      renderAssetRow={({ data, key, rowStyle }) => <PrivateAssetTableRow key={key} data={data} style={rowStyle} />}
      showBoards
    />
  );
});

PrivateBoardTableView.displayName = 'PrivateBoardTableView';
