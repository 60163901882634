import { Boards } from '@air/api';
import { Board } from '@air/api/types';
import { useQuery } from '@tanstack/react-query';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

export const BOARD_GUESTS = 'BOARD_GUESTS';

interface GetBoardGuestsParams {
  boardId: Board['id'];
  workspaceId?: string;
}

export const getBoardsGuestsKey = (params: GetBoardGuestsParams) => {
  return [BOARD_GUESTS, params];
};

export const useFetchBoardGuests = (boardId: string) => {
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;

  return useQuery({
    queryKey: getBoardsGuestsKey({ boardId, workspaceId }),

    queryFn: () => {
      if (!workspaceId) {
        throw new Error('No workspaceId found');
      }

      return Boards.listGuests({ boardId, workspaceId });
    },
    enabled: !!workspaceId,
  });
};
