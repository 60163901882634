import { Badge } from '@air/primitive-badge';

import { GUEST_BADGE } from '~/constants/testIDs';

export const GuestBadge = () => {
  return (
    <Badge color="yellow" className="ml-2 rounded-full" data-testid={GUEST_BADGE}>
      Guest
    </Badge>
  );
};
