import { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { ViewControlsBoardHeader } from '~/components/ViewControlsBoardHeader';
import { useGoToBoardPage } from '~/hooks/useGoToBoardPage';
import { centralizedBoardAncestorsSelector, centralizedBoardTitleSelector } from '~/store/centralizedBoard/selectors';
import { LocationBoard } from '~/store/router/types';

export const PrivateBoardViewControlsBoardHeader = memo(() => {
  const boardTitle = useSelector(centralizedBoardTitleSelector);
  const ancestors = useSelector(centralizedBoardAncestorsSelector);
  const { goToBoardPage } = useGoToBoardPage();

  const onBoardClick = useCallback(
    (board: LocationBoard) => {
      goToBoardPage({ board, trackLocation: 'breadcrumb' });
    },
    [goToBoardPage],
  );

  if (!boardTitle) return null;

  return <ViewControlsBoardHeader title={boardTitle} ancestors={ancestors} onBoardClick={onBoardClick} />;
});

PrivateBoardViewControlsBoardHeader.displayName = 'PrivateBoardViewControlsBoardHeader';
