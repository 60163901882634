import { memo } from 'react';

import { PrivateBoardFavoriteButton } from '~/_app/(private-board)/_components/PrivateBoardFavoriteButton';
import { TopBar } from '~/_components/TopBar';
import { PrivateBoardFiltersButton } from '~/components/Filters/FiltersButton/PrivateBoardFiltersButton';
import { PrivateBoardHeaderAncestors } from '~/components/PrivateBoard/PrivateBoardHeaderAncestors';

export const PrivateBoardMobileTopBar = memo(() => {
  return (
    <TopBar className="static">
      <div className="flex h-full items-center">
        <PrivateBoardHeaderAncestors />
      </div>

      <div className="flex h-full items-center">
        <PrivateBoardFavoriteButton size="large" />
        <PrivateBoardFiltersButton title="Filter Board" />
      </div>
    </TopBar>
  );
});

PrivateBoardMobileTopBar.displayName = 'PrivateBoardMobileTopBar';
