import { useMemo } from 'react';

import { usePrivateCustomFieldsContext } from '~/providers/PrivateCustomFieldsProvider';
import { currentKanbanGroupByCustomFieldIdSelector } from '~/store/configViews/selectors';
import { useAirSelector } from '~/utils/ReduxUtils';

export const useGetPrivateKanbanCustomField = () => {
  const { customFields } = usePrivateCustomFieldsContext();
  const customFieldId = useAirSelector(currentKanbanGroupByCustomFieldIdSelector);
  const baseCustomField = useMemo(
    () => customFields?.find((cf) => cf.id === customFieldId),
    [customFieldId, customFields],
  );
  return baseCustomField;
};
