import { useTrackBoardCustomFieldsSidePanelOpened } from '@air/analytics';
import { type BoardCustomFieldValue } from '@air/api/types';
import { CustomFieldsPanel, CustomFieldsPanelContent } from '@air/component-custom-fields-panel';
import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { AddCustomFieldButton } from '~/components/CustomFields/AddCustomFieldButton';
import { usePrivateCustomFieldsPanel } from '~/components/PrivateBoard/components/PrivateBoardCustomFieldsPanel/hooks/usePrivateCustomFieldsPanel';
import { PrivateBoardHeaderAncestors } from '~/components/PrivateBoard/PrivateBoardHeaderAncestors';
import { useGetAvailableCustomFields } from '~/hooks/useGetAvailableCustomFields';
import { useURLBoardIdSelector } from '~/hooks/useURLBoardIdSelector';
import { useURLLibraryIdSelector } from '~/hooks/useURLLibraryIdSelector';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { centralizedBoardLibraryIdSelector, centralizedBoardTitleSelector } from '~/store/centralizedBoard/selectors';
import { usePrivateBoardCustomFieldsData } from '~/swr-hooks/customFields/usePrivateBoardCustomFieldsData';
import { canCreateCustomFields } from '~/utils/permissions/workspacePermissions';

import { PrivateBoardPanelCustomFieldDateInput } from './PrivateBoardPanelCustomFieldDateInput';
import { PrivateBoardPanelCustomFieldPlainText } from './PrivateBoardPanelCustomFieldPlainText';
import { PrivateBoardPanelCustomFieldSelect } from './PrivateBoardPanelCustomFieldSelect';

export const PrivateBoardCustomFieldsPanel = memo(() => {
  const boardId = useURLBoardIdSelector();
  const boardTitle = useSelector(centralizedBoardTitleSelector);
  const urlLibraryId = useURLLibraryIdSelector();
  const libraryId = useSelector(centralizedBoardLibraryIdSelector);
  const { data: permissions } = useCurrentWorkspacePermissionsContext();

  const { refetch, data: customFields } = usePrivateBoardCustomFieldsData();
  const { trackBoardCustomFieldsSidePanelOpened } = useTrackBoardCustomFieldsSidePanelOpened();
  const { closePanel, isPanelOpen } = usePrivateCustomFieldsPanel();

  const { getAvailableCustomFields } = useGetAvailableCustomFields<BoardCustomFieldValue>({
    libraryId: libraryId || urlLibraryId,
  });

  useEffect(() => {
    if (isPanelOpen && boardId) {
      trackBoardCustomFieldsSidePanelOpened({ boardId });
    }
  }, [boardId, isPanelOpen, trackBoardCustomFieldsSidePanelOpened]);

  if (!isPanelOpen) {
    return null;
  }

  return (
    <CustomFieldsPanel
      breadcrumbs={<PrivateBoardHeaderAncestors />}
      title={boardTitle}
      onClose={closePanel}
      footer={
        canCreateCustomFields(permissions) ? (
          <AddCustomFieldButton trackingLocation="board-side-panel" onCreate={refetch} />
        ) : undefined
      }
    >
      <CustomFieldsPanelContent
        fields={getAvailableCustomFields(customFields?.data)}
        SelectCustomField={PrivateBoardPanelCustomFieldSelect}
        PlainTextCustomField={PrivateBoardPanelCustomFieldPlainText}
        DateCustomField={PrivateBoardPanelCustomFieldDateInput}
      />
    </CustomFieldsPanel>
  );
});

PrivateBoardCustomFieldsPanel.displayName = 'PrivateBoardCustomFieldsPanel';
