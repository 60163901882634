import { useTrackFollowedBoard, useTrackUnfollowedBoards } from '@air/analytics';
import { Check as CheckIcon } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { useToasts } from '@air/provider-toast';
import { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { FOLLOW_BUTTON } from '~/constants/testIDs';
import { useURLBoardIdSelector } from '~/hooks/useURLBoardIdSelector';
import { centralizedBoardTitleSelector } from '~/store/centralizedBoard/selectors';
import { useFollowBoard } from '~/swr-hooks/followedBoards/useFollowBoard';
import { useIsFollowingBoard } from '~/swr-hooks/followedBoards/useIsFollowingBoard';
import { useUnfollowBoards } from '~/swr-hooks/followedBoards/useUnfollowBoards';

export const FollowButton = memo(() => {
  const boardId = useURLBoardIdSelector();
  const centralizedBoardTitle = useSelector(centralizedBoardTitleSelector);
  const { followBoard } = useFollowBoard();
  const { unfollowBoards } = useUnfollowBoards();
  const { data: isFollowing, isFetched } = useIsFollowingBoard(boardId);
  const { trackFollowedBoard } = useTrackFollowedBoard();
  const { trackUnfollowedBoards } = useTrackUnfollowedBoards();
  const { showToast } = useToasts();

  const onClick = useCallback(async () => {
    if (boardId) {
      if (isFollowing) {
        unfollowBoards.mutate([boardId], {
          onSuccess: () => {
            trackUnfollowedBoards({ boardIds: [boardId], location: 'board header' });
            showToast(`You won’t receive notifications about changes in ${centralizedBoardTitle}`);
          },
        });
      } else {
        followBoard.mutate(boardId, {
          onSuccess: () => {
            trackFollowedBoard({ boardId });
            showToast(`You will receive notifications about changes in ${centralizedBoardTitle}`);
          },
        });
      }
    }
  }, [
    boardId,
    isFollowing,
    unfollowBoards,
    trackUnfollowedBoards,
    showToast,
    centralizedBoardTitle,
    followBoard,
    trackFollowedBoard,
  ]);

  if (!isFetched) return null;

  return (
    <Button
      appearance="ghost"
      color="grey"
      data-intercomid="intercom-follow-button"
      data-testid={FOLLOW_BUTTON}
      onClick={onClick}
      prefix={isFollowing && <CheckIcon className="h-4 w-4" />}
      size="large"
    >
      {isFollowing ? 'Following' : 'Follow'}
    </Button>
  );
});

FollowButton.displayName = 'FollowButton';
