import { useTrackUpgradedToProFrom } from '@air/analytics';
import { SubscriptionPlan } from '@air/api/types';
import { Banner } from '@air/primitive-banner';
import Link from 'next/link';

import { Routes } from '~/constants/routes';
import { INVITE_PEOPLE_UPGRADE } from '~/constants/testIDs';
import { NEW_TAB } from '~/constants/urls';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { canManageSubscription } from '~/utils/permissions/workspacePermissions';

export const BoardGuestsUpgradeHeader = ({ plan }: { plan: SubscriptionPlan }) => {
  const { trackUpgradedToProFrom } = useTrackUpgradedToProFrom();
  const { data: permissions } = useCurrentWorkspacePermissionsContext();
  const canUpgrade = canManageSubscription(permissions);

  return (
    <Banner
      appearance="info"
      className="mb-4"
      data-testid={INVITE_PEOPLE_UPGRADE}
      suffix={
        canUpgrade && (
          <Link
            className="font-medium text-inherit"
            onClick={() => trackUpgradedToProFrom({ plan, location: 'guest-invite-modal' })}
            href={Routes.workspaceAdmin.subscription}
            target={NEW_TAB}
          >
            Upgrade →
          </Link>
        )
      }
    >
      Upgrade to <b>Air Pro</b> to invite guest collaborators
    </Banner>
  );
};
