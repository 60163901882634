import { useTrackClickedAddCustomField, useTrackClickedSaveCustomField } from '@air/analytics';
import { Button } from '@air/primitive-button';
import { useAirModal } from '@air/provider-modal';
import { memo } from 'react';

import { CustomFieldsCreateModal } from '~/components/CustomFields/CustomFieldsCreateModal';
import { KanbanNullState } from '~/components/KanbanNullState/KanbanNullState';
import { KANBAN_NULL_STATE_ACTION_CREATE_CUSTOM_FIELD } from '~/constants/testIDs';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { canCreateCustomFields } from '~/utils/permissions/workspacePermissions';

export const PrivateKanbanNullState = memo(() => {
  const [showCreateModal] = useAirModal(CustomFieldsCreateModal);
  const { data: permissions } = useCurrentWorkspacePermissionsContext();
  const { trackClickedSaveCustomField } = useTrackClickedSaveCustomField();
  const { trackClickedAddCustomField } = useTrackClickedAddCustomField();

  return (
    <KanbanNullState
      title="This workspace doesn’t have any custom fields."
      action={
        canCreateCustomFields(permissions) && (
          <Button
            appearance="filled"
            color="grey"
            data-testid={KANBAN_NULL_STATE_ACTION_CREATE_CUSTOM_FIELD}
            onClick={() => {
              showCreateModal({
                onCreate: () => trackClickedSaveCustomField({ location: 'private-kanban-page' }),
              });
              trackClickedAddCustomField({ location: 'private-kanban-page' });
            }}
            size="large"
          >
            Add a custom field
          </Button>
        )
      }
      text={
        canCreateCustomFields(permissions)
          ? 'Add a single-select custom field to get started with Kanban view.'
          : 'To get started with Kanban View, ask your workspace admin to create a single-select custom field.'
      }
    />
  );
});

PrivateKanbanNullState.displayName = 'PrivateKanbanNullState';
