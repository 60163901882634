import { forwardRef } from 'react';

import { PrivateBoardTopBarActions } from '~/_app/(private-board)/_components/PrivateBoardTopBarActions';
import { TopBar } from '~/_components/TopBar';
import { PrivateBoardFiltersButton } from '~/components/Filters/FiltersButton/PrivateBoardFiltersButton';
import { PrivateBoardPageSearch } from '~/components/Search/PrivateBoardPageSearch';
import { PrivateHeaderSearchRow } from '~/components/Search/PrivateHeaderSearchRow';

export const PrivateBoardTopBar = forwardRef<HTMLDivElement>((_props, forwardedRef) => {
  return (
    <TopBar ref={forwardedRef}>
      <PrivateHeaderSearchRow
        trackSearchBarLocation="board"
        Search={PrivateBoardPageSearch}
        filtersButton={<PrivateBoardFiltersButton title="Filter Board" />}
      />

      <PrivateBoardTopBarActions />
    </TopBar>
  );
});

PrivateBoardTopBar.displayName = 'PrivateBoardTopBar';
