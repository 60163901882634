import { Download } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { Tooltip } from '@air/primitive-tooltip';
import { useBreakpointsContext } from '@air/provider-media-query';
import { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { BoardStatsBar } from '~/_components/BoardStatsBar';
import { ToggleNestedItemsButton } from '~/_components/ToggleNestedItemsButton';
import { useFormatBoardStats } from '~/_hooks/useFormatBoardStats';
import { useIsFlattenedView } from '~/hooks/search/useIsFlattenedView';
import { useIsSearchActive } from '~/hooks/search/useIsSearchActive';
import { useCurrentBoardPermissions } from '~/hooks/useCurrentBoardPermissions';
import { useDownloadPrivateBoard } from '~/hooks/useDownloadPrivateBoard';
import { CentralizedBoardSelectors } from '~/store/centralizedBoard/selectors';
import { useGetPrivateBoardStats } from '~/swr-hooks/boards/useGetPrivateBoardsStats';
import { canGenerateZip } from '~/utils/permissions/taskPermissions';

export const PrivateBoardStatsBar = memo(() => {
  const boardId = useSelector(CentralizedBoardSelectors.boardId);
  const { boardPermissions } = useCurrentBoardPermissions();
  const { isFlattenedView } = useIsFlattenedView();
  const { downloadPrivateBoard } = useDownloadPrivateBoard();
  const { data } = useGetPrivateBoardStats({ boardId, includeDescendants: isFlattenedView });
  const { formatBoardStats } = useFormatBoardStats();
  const { isSearchActive } = useIsSearchActive();
  const { isAboveMediumScreen } = useBreakpointsContext();

  const isEmptyBoard =
    data?.assetCount === 0 && data?.fileCount === 0 && data?.sizeBytes === 0 && data?.subBoardCount === 0;

  const isDownloadVisible = canGenerateZip(boardPermissions) && !!boardId;

  const onDownloadClick = useCallback(() => {
    if (boardId) {
      downloadPrivateBoard({ boardId, trackLocation: 'board-stats-bar' });
    }
  }, [boardId, downloadPrivateBoard]);

  return !isEmptyBoard && data ? (
    <BoardStatsBar
      actions={
        !isSearchActive && (
          <>
            {isAboveMediumScreen && (
              <>
                <ToggleNestedItemsButton />
                {isDownloadVisible && <div className="h-8 w-px bg-grey-4" />}
              </>
            )}

            {isDownloadVisible && (
              <Tooltip label="Download board">
                <IconButton
                  appearance="ghost"
                  color="grey"
                  icon={Download}
                  label="Download board"
                  onClick={onDownloadClick}
                />
              </Tooltip>
            )}
          </>
        )
      }
      className="bottom-16 md:bottom-0"
      stats={formatBoardStats(data)}
    />
  ) : null;
});

PrivateBoardStatsBar.displayName = 'PrivateBoardStatsBar';
