import { Button } from '@air/primitive-button';
import { useAirModal } from '@air/provider-modal';
import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  DropdownMenuWithActionSheet,
  DropdownMenuWithActionSheetProps,
} from '~/components/Menus/DropdownMenuWithActionSheet';
import { BoardGuestsModal } from '~/components/Modals/BoardGuestsModal/BoardGuestsModal';
import { SubscriptionExpiredModal } from '~/components/Modals/SubscriptionExpiredModal';
import { SharePrivateBoardModal } from '~/components/SharePrivateBoardModal/SharePrivateBoardModal';
import { intercomIDs } from '~/constants/intercomIDs';
import { SHARE_BUTTON } from '~/constants/testIDs';
import { useCurrentBoardPermissions } from '~/hooks/useCurrentBoardPermissions';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { useSubscriptionContext } from '~/providers/SubscriptionProvider';
import { centralizedBoardSelector } from '~/store/centralizedBoard/selectors';
import { isPrivateBoard } from '~/store/centralizedBoard/utils';
import { useGetBoardShareMenuOptions } from '~/utils/menuOptions/useGetBoardShareMenuOptions';
import { getBoardIdFromPath } from '~/utils/PathUtils';
import { canSeeBoardGuests } from '~/utils/permissions/boardPermissions';
import { canViewShortUrl } from '~/utils/permissions/shortUrlPermissions';
import { canViewSubscription } from '~/utils/permissions/workspacePermissions';

export interface ShareBoardButtonProps {
  trigger: DropdownMenuWithActionSheetProps['trigger'];
}

export const PrivateBoardShareButton = memo(({ trigger }: ShareBoardButtonProps) => {
  const [showSubscriptionExpiredModal] = useAirModal(SubscriptionExpiredModal);
  const [showBoardGuests] = useAirModal(BoardGuestsModal);
  const { data: subscription } = useSubscriptionContext();
  const currentBoard = useSelector(centralizedBoardSelector);
  const { boardPermissions } = useCurrentBoardPermissions();
  const canViewShareLink = canViewShortUrl(boardPermissions);
  const canSeeGuests = canSeeBoardGuests(boardPermissions);
  const [sharePrivateBoard] = useAirModal(SharePrivateBoardModal);
  const { data: workspacePermissions } = useCurrentWorkspacePermissionsContext();

  const canSeeSubscription = canViewSubscription(workspacePermissions);

  const onShareLink = useMemo(
    () =>
      canViewShareLink
        ? () => {
            if (currentBoard && isPrivateBoard(currentBoard)) {
              sharePrivateBoard({ boardId: currentBoard.id, trackLocation: 'header' });
            }
          }
        : undefined,
    [canViewShareLink, currentBoard, sharePrivateBoard],
  );

  const onAddMember = useMemo(
    () =>
      canSeeGuests
        ? () => {
            const boardId = getBoardIdFromPath(window.location.pathname);
            if (boardId)
              showBoardGuests({
                board: {
                  id: boardId,
                  library: currentBoard?.library,
                },
              });
          }
        : undefined,
    [canSeeGuests, currentBoard?.library, showBoardGuests],
  );

  const menuOptions = useGetBoardShareMenuOptions({
    onAddMember,
    onShareLink,
  });

  if (!menuOptions.length) {
    return null;
  }

  const hasActiveSubscription = !canSeeSubscription || (subscription && subscription.hasActiveSubscription);

  return !hasActiveSubscription ? (
    <Button
      appearance="filled"
      color="grey"
      data-testid={SHARE_BUTTON}
      data-intercomid={intercomIDs.HEADER_ACTIONS_SHARE_BUTTON}
      onClick={showSubscriptionExpiredModal}
      size="large"
    >
      Share
    </Button>
  ) : (
    <DropdownMenuWithActionSheet title="Share..." options={menuOptions} trigger={trigger} />
  );
});

PrivateBoardShareButton.displayName = 'ShareBoardButton';
