import { BoardCustomFieldValue } from '@air/api/types';
import { memo } from 'react';

import { PrivateBoardCustomFieldPlainText } from '~/components/PrivateBoard/PrivateBoardCustomFieldPlainText';
import { useCurrentBoardPermissions } from '~/hooks/useCurrentBoardPermissions';
import { useURLBoardIdSelector } from '~/hooks/useURLBoardIdSelector';
import { canChangeBoardCustomFields } from '~/utils/permissions/boardPermissions';

import { PrivateBoardPanelCustomFieldContainer } from './PrivateBoardPanelCustomFieldContainer';

export interface PrivateBoardPanelCustomFieldPlainTextProps {
  field: BoardCustomFieldValue;
}

export const PrivateBoardPanelCustomFieldPlainText = memo(({ field }: PrivateBoardPanelCustomFieldPlainTextProps) => {
  const boardId = useURLBoardIdSelector();
  const { boardPermissions } = useCurrentBoardPermissions();

  if (!boardId) {
    return null;
  }

  return (
    <PrivateBoardPanelCustomFieldContainer field={field}>
      <PrivateBoardCustomFieldPlainText
        field={field}
        trackingLocation="board-side-panel"
        boardId={boardId}
        canEditCustomFields={canChangeBoardCustomFields(boardPermissions)}
      />
    </PrivateBoardPanelCustomFieldContainer>
  );
});

PrivateBoardPanelCustomFieldPlainText.displayName = 'PrivateBoardPanelCustomFieldPlainText';
