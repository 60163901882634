import { useAirModal } from '@air/provider-modal';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import { SubscriptionExpiredModal } from '~/components/Modals/SubscriptionExpiredModal';
import { EditableContentHeaderTitle } from '~/components/PageHeader/EditableContentHeaderTitle';
import { BOARD_BLURB_TITLE } from '~/constants/testIDs';
import { useCurrentBoardPermissions } from '~/hooks/useCurrentBoardPermissions';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { useSubscriptionContext } from '~/providers/SubscriptionProvider';
import { centralizedBoardSelector, centralizedBoardTitleSelector } from '~/store/centralizedBoard/selectors';
import { isPrivateBoard } from '~/store/centralizedBoard/utils';
import { useUpdateBoard } from '~/swr-hooks/boards/useUpdateBoard';
import { containsRestrictedPathChars } from '~/utils/FileUtils';
import { canChangeBoardDetails } from '~/utils/permissions/boardPermissions';
import { canViewSubscription } from '~/utils/permissions/workspacePermissions';
import { useAirStore } from '~/utils/ReduxUtils';

const EditableTextBoardTitleValidationSchema = Yup.object().shape({
  ['editable-text-value']: Yup.string()
    .trim()
    .max(255, 'Cannot be longer than 255 characters')
    .test(
      'restricted-char-validation',
      'Characters : and | cannot be used',
      (val) => !containsRestrictedPathChars(val || ''),
    )
    .matches(/^[^\n]*$/, 'No line breaks allowed')
    .required('This field is required'),
});
export const PrivateBoardHeaderTitle = memo(() => {
  const store = useAirStore();
  const title = useSelector(centralizedBoardTitleSelector);
  const { updateBoard } = useUpdateBoard();
  const { data: subscription } = useSubscriptionContext();
  const [showSubscriptionExpiredModal] = useAirModal(SubscriptionExpiredModal);
  const { boardPermissions } = useCurrentBoardPermissions();
  const { data: workspacePermissions } = useCurrentWorkspacePermissionsContext();
  const canSeeSubscription = canViewSubscription(workspacePermissions);

  if (!title) return null;

  const hasActiveSubscription = !canSeeSubscription || (subscription && subscription.hasActiveSubscription);

  return (
    <EditableContentHeaderTitle
      label="Board title"
      testId={BOARD_BLURB_TITLE}
      editableTextTx={{ ml: -4 }}
      validationSchema={EditableTextBoardTitleValidationSchema}
      onSubmit={
        canChangeBoardDetails(boardPermissions) && hasActiveSubscription
          ? (title) => {
              const board = centralizedBoardSelector(store.getState());
              if (board && isPrivateBoard(board)) {
                updateBoard.mutate({ board, values: { title }, trackLocation: 'header' });
              }
            }
          : undefined
      }
      onClick={!hasActiveSubscription ? showSubscriptionExpiredModal : undefined}
      title={title}
    />
  );
});

PrivateBoardHeaderTitle.displayName = 'PrivateBoardHeaderTitle';
