import { Library } from '@air/api/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useMemo } from 'react';

import { useBoardSearchToken, UseSearchBoardTokenParams } from '~/components/Search/SearchTokens/useBoardSearchToken';
import { useLibrarySearchToken } from '~/components/Search/SearchTokens/useLibrarySearchToken';
import { IconType } from '~/types/icons';

export interface UsePrivateBoardSearchTokenParams extends UseSearchBoardTokenParams {
  library: Pick<Library, 'title'> & { Icon: IconType };
}

export const usePrivateBoardSearchToken = ({
  library,
  searchBarRef,
  boardName,
  onSearchTokenToggle,
  isFocused,
  hasValue,
}: UsePrivateBoardSearchTokenParams) => {
  const { libraries: librariesFlag } = useFlags();

  const {
    BoardToken,
    wasTokenRemoved: wasBoardTokenRemoved,
    showToken: showBoardToken,
    onBackspacePress: onBoardBackspacePress,
  } = useBoardSearchToken({
    boardName,
    hasValue,
    isFocused,
    onSearchTokenToggle,
    searchBarRef,
  });

  const {
    LibraryToken,
    showToken: showLibraryToken,
    wasTokenRemoved: wasLibraryTokenRemoved,
    onBackspacePress: onLibraryBackspacePress,
  } = useLibrarySearchToken({ library, hasValue, isFocused, searchBarRef, canRemove: wasBoardTokenRemoved });

  const SearchToken = useMemo(() => {
    return (
      <div className="flex space-x-2">
        {librariesFlag && LibraryToken}
        {BoardToken}
      </div>
    );
  }, [librariesFlag, LibraryToken, BoardToken]);

  const showTokens = useCallback(() => {
    showLibraryToken();
    showBoardToken();
  }, [showBoardToken, showLibraryToken]);

  const onBackspacePress = useCallback(() => {
    if (wasBoardTokenRemoved) {
      onLibraryBackspacePress();
    } else {
      onBoardBackspacePress();
    }
  }, [wasBoardTokenRemoved, onLibraryBackspacePress, onBoardBackspacePress]);

  return librariesFlag
    ? {
        SearchToken,
        isBoardTokenRemoved: wasBoardTokenRemoved,
        isLibraryTokenRemoved: wasLibraryTokenRemoved,
        showTokens,
        onBackspacePress,
      }
    : {
        SearchToken,
        isBoardTokenRemoved: wasBoardTokenRemoved,
        isLibraryTokenRemoved: true,
        showTokens: showBoardToken,
        onBackspacePress: onBoardBackspacePress,
      };
};
