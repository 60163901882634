import { Boards } from '@air/api';
import { BoardGuestResponse, WorkspaceMemberResponse, WorkspaceUserRole } from '@air/api/types';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { getWorkspaceMembersKey } from '~/swr-hooks/members/useGetWorkspaceMembers';
import { sanitizeEmail } from '~/utils/EmailUtils';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

import { getBoardsGuestsKey } from './useFetchBoardGuests';

export const useUpdateGuestRole = () => {
  const { currentWorkspace } = useCurrentWorkspace();
  const queryClient = useQueryClient();
  const workspaceId = currentWorkspace?.id;

  const updateGuestRole = useCallback(
    async ({
      role,
      boardId,
      member,
    }: {
      role: WorkspaceUserRole;
      boardId: string;
      member: WorkspaceMemberResponse | BoardGuestResponse['member'];
    }) => {
      if (!workspaceId) {
        throw new Error('No workspaceId found');
      }

      try {
        await Boards.updateGuests({
          boardId: boardId,
          guests: [
            {
              accountId: member.accountId || '',
              email: sanitizeEmail(member.email),
              roleId: role.id,
            },
          ],
          workspaceId,
        });

        queryClient.invalidateQueries({ queryKey: getWorkspaceMembersKey(workspaceId) });
        queryClient.invalidateQueries({ queryKey: getBoardsGuestsKey({ boardId, workspaceId }) });
      } catch (error) {
        reportErrorToBugsnag({
          error,
          context: 'Failed to update a guest role',
          metadata: { data: { boardId }, role, member },
        });
      }
    },
    [queryClient, workspaceId],
  );

  return {
    updateGuestRole,
  };
};
