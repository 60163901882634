import { useTrackBoardViewSaved } from '@air/analytics';
import { Boards, GetBoardResponse } from '@air/api';
import { Button } from '@air/primitive-button';
import { useToasts } from '@air/provider-toast';
import { useQueryClient } from '@tanstack/react-query';
import produce from 'immer';
import { memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getPageBoardKey } from '~/constants/react-query-keys';
import { useCurrentBoardPermissions } from '~/hooks/useCurrentBoardPermissions';
import { useRevalidatePublicBoard } from '~/hooks/useRevalidatePublicBoard';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { setSavedConfigurableViewAction } from '~/store/configViews/actions';
import {
  ConfigViewsSelectors,
  currentConfigurableViewSelector,
  savedConfigurableViewsSelector,
} from '~/store/configViews/selectors';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';
import { getBoardIdFromPath } from '~/utils/PathUtils';
import { canChangeBoardDetails } from '~/utils/permissions/boardPermissions';
import { useAirStore } from '~/utils/ReduxUtils';

export const PrivateBoardSaveViewButton = memo(() => {
  const store = useAirStore();
  const dispatch = useDispatch();
  const [isSavingView, setIsSavingView] = useState(false);
  const currentConfigViewHasChanges = useSelector(ConfigViewsSelectors.currentConfigViewHasChanges);
  const { showToast } = useToasts();
  const queryClient = useQueryClient();
  const { trackBoardViewSaved } = useTrackBoardViewSaved();
  const { boardPermissions } = useCurrentBoardPermissions();
  const canEditBoard = canChangeBoardDetails(boardPermissions);
  const { revalidatePublicBoard } = useRevalidatePublicBoard();
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;

  const saveView = useCallback(async () => {
    const viewToSave = currentConfigurableViewSelector(store.getState());
    const boardId = getBoardIdFromPath(window.location.pathname);
    const views = savedConfigurableViewsSelector(store.getState());

    if (!workspaceId) {
      throw new Error('No workspaceId found');
    }

    if (viewToSave && boardId) {
      setIsSavingView(true);
      try {
        const configurableView = await Boards.updateBoardView({ boardId, ...viewToSave, workspaceId });
        dispatch(setSavedConfigurableViewAction({ configurableView }));
        trackBoardViewSaved({ boardId });

        revalidatePublicBoard(boardId);

        const newViews = produce(views, (draft) => {
          const indexOfView = draft.findIndex((v) => v.id === viewToSave.id);
          if (draft && indexOfView !== -1) {
            draft.forEach((v) => (v.isDefault = false));
            draft[indexOfView] = viewToSave;
          }
        });

        queryClient.setQueryData<GetBoardResponse | undefined>(getPageBoardKey(boardId), (boardCache) => {
          if (!boardCache) return undefined;
          return produce(boardCache, (draft) => {
            draft.views = newViews;
          });
        });
      } catch (error) {
        showToast(`There was an error saving the view.`);

        reportErrorToBugsnag({
          error,
          context: `Failed to save configurable view`,
          metadata: {
            data: {
              viewToSave,
            },
          },
        });
      }
      setIsSavingView(false);
    }
  }, [dispatch, queryClient, revalidatePublicBoard, showToast, store, trackBoardViewSaved, workspaceId]);

  const canSaveView = !!canEditBoard && currentConfigViewHasChanges;

  return canSaveView ? (
    <Button
      appearance="outline"
      color="blue"
      data-testid="SAVE_VIEW_BUTTON"
      isLoading={isSavingView}
      disabled={isSavingView}
      onClick={saveView}
      size="medium"
    >
      Save view
    </Button>
  ) : null;
});

PrivateBoardSaveViewButton.displayName = 'PrivateBoardSaveViewButton';
