import { Boards } from '@air/api';
import { useQuery } from '@tanstack/react-query';

import { getPageBoardKey } from '~/constants/react-query-keys';

export const usePrivateBoardPage = (boardId?: string | null) => {
  return useQuery({
    queryKey: getPageBoardKey(boardId),

    queryFn: () => Boards.getWithNoWorkspaceId({ boardId: boardId! }),
    enabled: !!boardId,
    /**
     * When we inject boards into the cache with getPageBoardKey,
     * no matter how long ago it was, we want to always refetch
     * for the latest board information
     */
    staleTime: 0,
  });
};
