import { useCurrentBoardPermissions } from '~/hooks/useCurrentBoardPermissions';
import { usePrivateBoardCustomFieldsData } from '~/swr-hooks/customFields/usePrivateBoardCustomFieldsData';
import { canChangeBoardCustomFields } from '~/utils/permissions/boardPermissions';

export const useCanOpenPrivateCustomFieldsPanel = () => {
  const { data: customFieldsResponse } = usePrivateBoardCustomFieldsData();

  const { boardPermissions } = useCurrentBoardPermissions();

  // admin privilege or if the user can edit board, only show it if there are custom fields in workspace
  const canOpenCustomFieldsPanel =
    canChangeBoardCustomFields(boardPermissions) && !!customFieldsResponse && !!customFieldsResponse.data.length;

  return { canOpenCustomFieldsPanel };
};
