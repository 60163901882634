import { BoardCustomFieldValue } from '@air/api/types';
import { memo } from 'react';

import { PrivateBoardCustomFieldSelect } from '~/components/PrivateBoard/PrivateBoardCustomFieldSelect';
import { useCurrentBoardPermissions } from '~/hooks/useCurrentBoardPermissions';
import { useURLBoardIdSelector } from '~/hooks/useURLBoardIdSelector';
import { canChangeBoardCustomFields } from '~/utils/permissions/boardPermissions';

import { PrivateBoardPanelCustomFieldContainer } from './PrivateBoardPanelCustomFieldContainer';

export interface PrivateBoardPanelCustomFieldSelectProps {
  field: BoardCustomFieldValue;
}

export const PrivateBoardPanelCustomFieldSelect = memo(({ field }: PrivateBoardPanelCustomFieldSelectProps) => {
  const boardId = useURLBoardIdSelector();
  const { boardPermissions } = useCurrentBoardPermissions();

  if (!boardId) {
    return null;
  }

  return (
    <PrivateBoardPanelCustomFieldContainer field={field}>
      <PrivateBoardCustomFieldSelect
        field={field}
        trackingLocation="board-side-panel"
        canEditCustomFields={canChangeBoardCustomFields(boardPermissions)}
        boardId={boardId}
      />
    </PrivateBoardPanelCustomFieldContainer>
  );
});

PrivateBoardPanelCustomFieldSelect.displayName = 'PrivateBoardPanelCustomFieldSelect';
