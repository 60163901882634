import { useContextMenu } from '../../hooks/useContextMenu';
import { usePrivateBoardActions } from './usePrivateBoardActions';

export const usePrivateBoardGalleryContextMenu = () => {
  const { options } = usePrivateBoardActions({ trackLocation: 'board-container-right-click' });

  return useContextMenu({
    id: 'private-gallery-context-menu',
    options,
  });
};
