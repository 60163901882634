import { memo } from 'react';

import { AddButton } from '~/components/AddButton/AddButton';

import { usePrivateBoardActions } from './usePrivateBoardActions';

export const PrivateBoardAddButton = memo(() => {
  const { privateBoardActionsOptions } = usePrivateBoardActions({ trackLocation: 'header-bar-add-button' });

  if (!privateBoardActionsOptions.length) {
    return null;
  }

  return <AddButton options={privateBoardActionsOptions} />;
});

PrivateBoardAddButton.displayName = 'PrivateBoardAddButton';
