import { useTrackRemovedUserFromWorkspace } from '@air/analytics';
import { Boards } from '@air/api';
import { BoardGuestResponse, WorkspaceUserRolesDefinition } from '@air/api/types';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { getWorkspaceMembersKey } from '~/swr-hooks/members/useGetWorkspaceMembers';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

import { useCurrentWorkspaceMember } from '../members/useCurrentWorkspaceMember';
import { getBoardsGuestsKey } from './useFetchBoardGuests';

type RemoveMemberType = BoardGuestResponse['member'] & {
  roles?: WorkspaceUserRolesDefinition;
};

export const useDeleteGuestFromBoards = () => {
  const { currentWorkspace } = useCurrentWorkspace();
  const queryClient = useQueryClient();
  const { currentWorkspaceMember } = useCurrentWorkspaceMember();
  const { trackRemovedUserFromWorkspace } = useTrackRemovedUserFromWorkspace();
  const workspaceId = currentWorkspace?.id;

  const deleteGuestFromBoards = useCallback(
    async ({ boardIds, member }: { boardIds: string[]; member: RemoveMemberType }) => {
      if (!workspaceId) {
        throw new Error('No workspaceId found');
      }

      try {
        await Promise.all(
          boardIds.map(async (boardId) => {
            queryClient.setQueryData<{ guests: BoardGuestResponse[] }>(
              getBoardsGuestsKey({ boardId, workspaceId }),
              (oldGuests) => ({
                guests: oldGuests?.guests.filter((g) => g.member.accountId !== member.accountId) ?? [],
              }),
            );

            await Boards.removeGuests({
              boardId,
              guests: member.accountId ? [{ accountId: member.accountId }] : [{ email: member.email }],
              workspaceId,
            });

            queryClient.invalidateQueries({ queryKey: getBoardsGuestsKey({ boardId, workspaceId }) });
          }),
        );

        // filter all boards a user is a guest of, by the boards they are currently being removed from
        const usersRemainingBoards = member.roles?.guest.filter((board) => !boardIds.includes(board.boardId));
        // if the user is not a guest of any remaining boards, or a full member, track them as a removed workspace member
        if (!usersRemainingBoards?.length && member.roles?.member.displayName === 'Default') {
          trackRemovedUserFromWorkspace({
            removerId: currentWorkspaceMember?.accountId ?? '',
            removedUserId: member.accountId ?? '',
            removedUserRole: member.roles
              ? boardIds.length > 1
                ? `guest Multiple`
                : `guest ${member.roles.guest[0].displayName}`
              : 'roles unavailable',
          });
        }

        queryClient.invalidateQueries({ queryKey: getWorkspaceMembersKey(workspaceId) });
      } catch (error) {
        reportErrorToBugsnag({
          error,
          context: 'Failed to remove guest from board',
          metadata: {
            Data: {
              boardIds,
              email: member.email,
            },
          },
        });
      }
    },
    [currentWorkspaceMember?.accountId, queryClient, trackRemovedUserFromWorkspace, workspaceId],
  );

  return {
    deleteGuestFromBoards,
  };
};
