import { memo } from 'react';

import { EditCustomFieldsButton, EditCustomFieldsButtonProps } from '~/_components/EditCustomFieldsButton';
import { usePrivateCustomFieldsPanel } from '~/components/PrivateBoard/components/PrivateBoardCustomFieldsPanel/hooks/usePrivateCustomFieldsPanel';

export type PrivateBoardEditCustomFieldsButtonProps = Omit<EditCustomFieldsButtonProps, 'onClick'>;

export const PrivateBoardEditCustomFieldsButton = memo((props: PrivateBoardEditCustomFieldsButtonProps) => {
  const { togglePanel } = usePrivateCustomFieldsPanel();

  if (!togglePanel) return null;

  return <EditCustomFieldsButton onClick={togglePanel} {...props} />;
});

PrivateBoardEditCustomFieldsButton.displayName = 'PrivateBoardEditCustomFieldsButton';
