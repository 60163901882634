import { Boards } from '@air/api';
import { useQuery } from '@tanstack/react-query';

import { getPrivateBoardCustomFieldsKey } from '~/constants/react-query-keys';
import { useBoardPermissions } from '~/hooks/useBoardPermissions';
import { useURLBoardIdSelector } from '~/hooks/useURLBoardIdSelector';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { canSeeBoardCustomFields } from '~/utils/permissions/boardPermissions';

export const usePrivateBoardCustomFieldsData = () => {
  const boardId = useURLBoardIdSelector();
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;
  const { boardPermissions } = useBoardPermissions({ boardId });

  const canViewCustomFields = canSeeBoardCustomFields(boardPermissions);

  return useQuery({
    queryKey: getPrivateBoardCustomFieldsKey(boardId),

    queryFn: () => {
      if (!workspaceId) {
        throw new Error('No workspaceId found');
      }

      if (!boardId) {
        throw new Error('No boardId found');
      }

      return Boards.listCustomFields({
        boardId,
        workspaceId,
        options: {
          limit: 100,
        },
      });
    },
    enabled: !!boardId && !!workspaceId && canViewCustomFields,
  });
};
