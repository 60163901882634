import { BoardGuestResponse } from '@air/api/types';
import { DropdownMenuOption } from '@air/primitive-dropdown-menu';
import { useAirModal } from '@air/provider-modal';
import { useToasts } from '@air/provider-toast';
import { useQueryClient } from '@tanstack/react-query';
import { isBoolean } from 'lodash';

import { DropdownMenuWithSelectedLabelButton } from '~/components/Menus/DropdownMenuWithSelectedLabelButton';
import { UpgradeGuestRole } from '~/components/Modals/UpgradeGuestRole/UgradeGuestRole';
import { GUEST_ROLE_MENU } from '~/constants/testIDs';
import { useBoardPermissions } from '~/hooks/useBoardPermissions';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useDeleteGuestFromBoards } from '~/swr-hooks/boards/useDeleteGuestFromBoard';
import { useUpdateGuestRole } from '~/swr-hooks/boards/useUpdateGuestRole';
import { useCurrentWorkspaceMember } from '~/swr-hooks/members/useCurrentWorkspaceMember';
import { getWorkspaceMembersKey } from '~/swr-hooks/members/useGetWorkspaceMembers';
import { useUserByAccountIdOrEmail } from '~/swr-hooks/members/useUserByAccountIdOrEmail';
import { useWorkspaceGuestRoles } from '~/swr-hooks/members/useWorkspaceGuestRoles';
import { canChangeBoardGuestsRoles, canRemoveBoardGuests } from '~/utils/permissions/boardPermissions';
import { isPermissionsGTE } from '~/utils/PermissionsUtils';

interface GuestRoleMenuProps {
  boardId: string;
  roleId: string;
  member: BoardGuestResponse['member'] & { isBilled?: boolean };
}

export const GuestRoleMenu = ({ boardId, roleId, member, member: { firstName, lastName } }: GuestRoleMenuProps) => {
  const { showToast } = useToasts();
  const { currentWorkspaceMember } = useCurrentWorkspaceMember();
  const { currentWorkspace } = useCurrentWorkspace();
  const [showUpgradeGuestRoleModal, closeUpgradeGuestRoleModal] = useAirModal(UpgradeGuestRole);
  const { updateGuestRole } = useUpdateGuestRole();
  const { deleteGuestFromBoards } = useDeleteGuestFromBoards();
  const { guestRoles } = useWorkspaceGuestRoles();
  const currentRole = guestRoles?.find((r) => r.id === roleId);
  const queryClient = useQueryClient();
  const { user: fullMember } = useUserByAccountIdOrEmail({ userId: member.email ?? member.accountId });

  const { boardPermissions } = useBoardPermissions({ boardId });

  if (!guestRoles || !currentRole || !currentWorkspaceMember || !boardPermissions) {
    return null;
  }

  const options: DropdownMenuOption[] = [];

  if (canChangeBoardGuestsRoles(boardPermissions)) {
    options.push(
      ...guestRoles.map(
        (role): DropdownMenuOption => ({
          id: role.id,
          description: role.description || undefined,
          label: role.displayName,
          disabled:
            !isPermissionsGTE(boardPermissions, role.permissions) ||
            !isPermissionsGTE(boardPermissions, currentRole.permissions),
          onSelect: async () => {
            const updateRole = async () => {
              await updateGuestRole({ role, boardId, member });
              showToast(
                `${firstName && lastName ? `${firstName} ${lastName}’s role changed` : 'Member’s role changed'}`,
              );
            };

            try {
              if (isBoolean(member.isBilled) && !member.isBilled && role.billable) {
                showUpgradeGuestRoleModal({
                  member,
                  onConfirm: async () => {
                    updateRole();
                    closeUpgradeGuestRoleModal();
                  },
                });
              } else {
                updateRole();
              }
            } catch (_error) {
              showToast(`Failed to update member. Please try again later.`);
            }
          },
          type: 'item',
        }),
      ),
    );
  }

  if (canRemoveBoardGuests(boardPermissions)) {
    if (options.length > 0) {
      options.push({
        type: 'separator',
        id: 'remove-separator',
      });
    }
    options.push({
      onSelect: async () => {
        try {
          await deleteGuestFromBoards({ boardIds: [boardId], member: fullMember ?? member });
          if (!!currentWorkspace?.id) {
            queryClient.invalidateQueries({ queryKey: getWorkspaceMembersKey(currentWorkspace?.id) });
          }
          showToast(
            `${
              member.firstName && member.lastName
                ? `${member.firstName} ${member.lastName} was removed from 1 board`
                : 'Guest was removed from 1 board'
            }`,
          );
        } catch (_error) {
          showToast('Something went wrong please try again later.');
        }
      },
      label: currentWorkspaceMember.accountId === member.accountId ? 'Leave this board' : 'Remove from Board',
      type: 'item',
      id: 'remove',
      className: 'text-red-10',
    });
  }

  return (
    <DropdownMenuWithSelectedLabelButton
      selectedOption={{
        id: currentRole.id,
        label: currentRole.displayName,
      }}
      options={options}
      disabled={!options.length}
      testId={GUEST_ROLE_MENU}
    />
  );
};
