import { Board } from '@air/api/types';

import { useLibraryPermissions } from '~/components/LibraryBeta/hooks/useLibraryPermissions';
import { useBoardPermissions } from '~/hooks/useBoardPermissions';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';

/**
 * This hook takes a board and returns the permissions of the board's container.
 * It first checks if the board has a parentId, and if so, returns the permissions of the parent board.
 * It then checks if the board has a library (it lives in a library), and if so, returns the permissions of the library.
 * If the board has neither a parent nor a library (it's a workspace board), it returns the permissions of the current workspace.
 */
export const useBoardContainerPermissions = (board: Board | undefined) => {
  const { data: workspacePermissions } = useCurrentWorkspacePermissionsContext();
  const { libraryPermissions } = useLibraryPermissions({ libraryId: board?.library?.id });
  const { boardPermissions: parentBoardPermissions } = useBoardPermissions({ boardId: board?.parentId ?? undefined });

  return {
    boardContainerPermissions: board?.parentId
      ? parentBoardPermissions
      : board?.library
      ? libraryPermissions
      : workspacePermissions,
  };
};
