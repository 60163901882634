import { Link } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { memo } from 'react';

import { PrivateBoardShareButton } from '~/components/PrivateBoard/PrivateBoardShareButton';
import { intercomIDs } from '~/constants/intercomIDs';
import { SHARE_BUTTON } from '~/constants/testIDs';

export const PrivateBoardMobileShareButton = memo(() => {
  return (
    <PrivateBoardShareButton
      trigger={
        <IconButton
          appearance="ghost"
          color="grey"
          data-intercomid={intercomIDs.HEADER_ACTIONS_SHARE_BUTTON}
          data-testid={SHARE_BUTTON}
          icon={Link}
          label="Share"
          size="large"
        />
      }
    />
  );
});

PrivateBoardMobileShareButton.displayName = 'PrivateBoardMobileShareButton';
