import { memo } from 'react';
import { useSelector } from 'react-redux';

import { useCurrentBoardPermissions } from '~/hooks/useCurrentBoardPermissions';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { useSubscriptionContext } from '~/providers/SubscriptionProvider';
import { centralizedBoardDescriptionSelector, centralizedBoardSelector } from '~/store/centralizedBoard/selectors';
import { isPrivateBoard } from '~/store/centralizedBoard/utils';
import { useUpdateBoard } from '~/swr-hooks/boards/useUpdateBoard';
import { canChangeBoardDetails } from '~/utils/permissions/boardPermissions';
import { canViewSubscription } from '~/utils/permissions/workspacePermissions';
import { useAirStore } from '~/utils/ReduxUtils';

import { BoardHeaderDescription } from '../Workspace/BoardHeader/BoardHeaderDescription';

export const PrivateBoardHeaderDescription = memo(() => {
  const store = useAirStore();
  const description = useSelector(centralizedBoardDescriptionSelector);
  const { boardPermissions } = useCurrentBoardPermissions();
  const canEdit = canChangeBoardDetails(boardPermissions);
  const { data: subscription } = useSubscriptionContext();
  const { updateBoard } = useUpdateBoard();
  const { data: workspacePermissions } = useCurrentWorkspacePermissionsContext();

  const canSeeSubscription = canViewSubscription(workspacePermissions);
  const hasActiveSubscription = !canSeeSubscription || (subscription && subscription.hasActiveSubscription);

  return (
    <BoardHeaderDescription
      description={description || ''}
      onSubmit={
        canEdit && hasActiveSubscription
          ? (description) => {
              const board = centralizedBoardSelector(store.getState());
              if (board && isPrivateBoard(board)) {
                updateBoard.mutate({ board, values: { description }, trackLocation: 'header' });
              }
            }
          : undefined
      }
    />
  );
});

PrivateBoardHeaderDescription.displayName = 'PrivateBoardHeaderDescription';
