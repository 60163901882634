import { Board } from '@air/api/types';
import { Modal, ModalCloseButton, ModalDescription, ModalTitle } from '@air/primitive-modal';

import { GuestRoleMenu } from '~/components/GuestRoleMenu';
import { GuestBadge } from '~/components/MemberInfo/GuestBadge';
import { MemberInfo } from '~/components/MemberInfo/MemberInfo';
import { BoardGuestInput } from '~/components/Modals/BoardGuestsModal/BoardGuestInput';
import { usePlanFeature } from '~/hooks/usePlanFeature';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useFetchBoardGuests } from '~/swr-hooks/boards/useFetchBoardGuests';

import { BoardGuestModalDescription } from './BoardGuestModalDescription';
import { BoardGuestsUpgradeHeader } from './BoardGuestsUpgradeHeader';

export interface BoardGuestsModalProps {
  board: Pick<Board, 'id' | 'library'>;
}

/**
 * This is the content of Invite guests tab in ShareBoard modal.
 * It displays board guests list and and input to invite a new guest.
 * Member roles and board guests are fetched on mount.
 */
export const BoardGuestsModal = ({ board, onClose }: AirModalProps<BoardGuestsModalProps>) => {
  const { data } = useFetchBoardGuests(board.id);
  const canUseGuestMembers = usePlanFeature('guestMembers');
  const { currentWorkspace } = useCurrentWorkspace();

  if (!currentWorkspace) return null;

  return (
    <Modal isOpen onDismiss={onClose} dangerouslyBypassFocusLock>
      <header className="flex flex-col gap-2">
        <div className="flex items-center justify-between">
          <ModalTitle>Invite guests</ModalTitle>
          <ModalCloseButton onClick={onClose} />
        </div>
        {canUseGuestMembers && (
          <ModalDescription>
            <BoardGuestModalDescription />
          </ModalDescription>
        )}
      </header>
      <div className="mt-3">
        {!canUseGuestMembers && <BoardGuestsUpgradeHeader plan={currentWorkspace.plan} />}
        <BoardGuestInput boardId={board.id} canUseGuestMembers={canUseGuestMembers} />
        <hr className="my-6 h-px border-0 bg-grey-5" />
        <div className="flex items-center">
          <img
            alt={`Image for ${currentWorkspace.name}`}
            className="mr-3 h-10 w-10 shrink-0 rounded object-cover"
            src={currentWorkspace.image}
          />
          <span className="flex flex-wrap gap-1 text-16 text-grey-10">
            <span className="whitespace-nowrap">{board.library ? 'Everyone in the library ' : 'Everyone in '}</span>
            <div className="truncate text-grey-12">{board.library?.title ?? currentWorkspace?.name}</div>{' '}
            <span className="whitespace-nowrap"> can access this board</span>
          </span>
        </div>
        {data && (
          <div className="mt-2">
            {data.guests.map((boardGuest) => (
              <div
                className="flex items-center justify-between py-2"
                key={boardGuest.member.accountId || boardGuest.member.email}
              >
                <MemberInfo member={boardGuest.member} nameAdornment={<GuestBadge />} />
                <GuestRoleMenu boardId={board.id} roleId={boardGuest.role.id} member={boardGuest.member} />
              </div>
            ))}
          </div>
        )}
      </div>
    </Modal>
  );
};
