import { BoardCustomFieldValue } from '@air/api/types';
import { useAirModal } from '@air/provider-modal';
import { memo } from 'react';

import { CustomFieldDeleteModal } from '~/components/CustomFields/CustomFieldDeleteModal';
import {
  PanelCustomFieldContainer,
  PanelCustomFieldContainerProps,
} from '~/components/RightPanel/CustomFields/PanelCustomFieldContainer';
import { useEditBoardCustomField } from '~/components/RightPanel/CustomFields/useEditBoardCustomField';
import { useURLBoardIdSelector } from '~/hooks/useURLBoardIdSelector';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { useDeleteWorkspaceCustomField } from '~/swr-hooks/customFields/useDeleteWorkspaceCustomField';
import { canDeleteCustomFields, canEditCustomFields } from '~/utils/permissions/workspacePermissions';

export type PrivateBoardPanelCustomFieldContainerProps = Omit<
  PanelCustomFieldContainerProps<BoardCustomFieldValue>,
  'canDeleteCustomFields' | 'canEditCustomFields' | 'onClick'
>;

export const PrivateBoardPanelCustomFieldContainer = memo(
  ({ field, ...rest }: PrivateBoardPanelCustomFieldContainerProps) => {
    const boardId = useURLBoardIdSelector();
    const { data: permissions } = useCurrentWorkspacePermissionsContext();
    const canEdit = canEditCustomFields(permissions);
    const canDelete = canDeleteCustomFields(permissions);
    const { editBoardCustomField } = useEditBoardCustomField();
    const [showDeleteModal] = useAirModal(CustomFieldDeleteModal);
    const { deleteCustomField } = useDeleteWorkspaceCustomField();

    return (
      <PanelCustomFieldContainer
        canDeleteCustomFields={canDelete}
        canEditCustomFields={canEdit}
        onClick={() => {
          if (!boardId) {
            return;
          }
          if (canEdit) {
            editBoardCustomField({
              boardId,
              field,
              trackingLocation: 'board-side-panel',
            });
          } else if (canDelete) {
            showDeleteModal({
              handleConfirmDeleteClick: () =>
                deleteCustomField.mutateAsync({ customFieldId: field.id, trackingLocation: 'board-side-panel' }),
            });
          }
        }}
        field={field}
        {...rest}
      />
    );
  },
);

PrivateBoardPanelCustomFieldContainer.displayName = 'PrivateBoardPanelCustomFieldContainer';
